<template>
  <div class="doc-outter">
    <div class="doc-title">
      <h3>公司简介</h3>
    </div>
    <div class="doc-content">
      <h4 class="t-c">幸福航空简介概览</h4>

      <p>
        幸福航空有限责任公司(以下简称“幸福航空”)于 2008 年由中
        国航空工业集团发起组建，2018 年 11 月起由西安航空航天投资股份
        有限公司控股，是由西安国资控股的航空公司。幸福航空成立以来一
        直承担着支持我国国产民机发展的重任，为国产民机推广和应用做出
        了巨大的贡献。
      </p>
      <p>
        目前,幸福航空是全球最大且唯一形成商业规模的国产民机运营 商，拥有 24
        架新舟 60 飞机，3 架波音 737 飞机，员工 1000 余名， 建成过夜基地 6
        个，开通航线 40 余条。
      </p>
      <p>
        幸福航空在西安市政府的坚强领导和控股股东西安航投的大力
        支持下，将以西安咸阳国际机场为主干线基地，干支并举，加快飞机
        引进速度，优化航线网络布局，夯实安全基础，提升服务质量，积极
        践行民航“真情服务”;此外，幸福航空还将致力于成为具有西安本
        土特色的航空企业品牌，为构建陕西开放型经济新格局、谱写陕西新
        时代追赶超越新篇章贡献“幸福”的力量。
      </p>
      <h4 class="t-c">OVERVIEW OF JOYAIR</h4>
      <p>
        Joyair Co., Ltd. (hereinafter referred to as "Joyair") was founded by
        China Aviation Industry Group in 2008, and has been controlled by Xi`an
        Aerospace Investment Co.,Ltd. since November 2018. It is an airline
        controlled by Xi'an state owned assets. Since its establishment, Joyair
        has been undertaking the important task of supporting the development of
        domestic civil aircraft in China, and has made great contributions to
        the promotion and application of domestic civil aircraft.
      </p>
      <p>
        At present, Joyair is the world's largest and only domestic civil
        aircraft operator with commercial scale. It has 24 Modern Ark 60, 3
        Boeing 737-800, more than 1000 employees, 6 overnight bases and more
        than 40 routes.
      </p>
      <p>
        In the future, with the strong leadership of Xi'an municipal government
        and the strong support of Xi`an Aerospace Investment Co.,Ltd., the
        controlling shareholder, Joyair will take Xi'an Xianyang International
        Airport as the main base, develop both main lines and branch lines,
        accelerate the speed of aircraft introduction, optimize the route
        network layout, lay a solid foundation for safety, improve service
        quality, and actively practice the "sincere service" of civil aviation.
        In addition, Joyair will also be committed to becoming an aviation
        enterprise with local characteristics of Xi'an and making constructions
        to writing Shaanxi Province’s catching up and surpassing new chapter in
        the new era.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.wrapCont p {
  margin-bottom: 8px;
}
.zhu_wrap {
  margin-bottom: 8px;
}
.zhu_wrap p {
  margin-bottom: 0;
  padding-left: 12px;
  line-height: 180%;
}
</style>
